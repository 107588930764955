import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import knowledgeService from '../../../../../services/knowledgeService';

const NavSearch = (props) => {
    const { windowWidth } = props;
    const [isOpen, setIsOpen] = useState(windowWidth < 600);
    const [searchString, setSearchString] = useState(windowWidth < 600 ? '100px' : '');
    const [searchResults, setSearchResults] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const handleSearch = async (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            setIsSearching(true);
            const results = await knowledgeService.aiPoweredKBSearch({ query: searchQuery });
            if (results) {
                setSearchResults(results);
            }
            setIsSearching(false);
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <React.Fragment>
            <div id="main-search" className={isOpen ? 'main-search open' : 'main-search'}>
                <div className="input-group">
                    {/* <input 
                        type="text" 
                        id="m-search" 
                        className="form-control" 
                        placeholder="Search . . ." 
                        style={{ width: searchString }} 
                        onFocus={() => setShowModal(true)}
                        onClick={() => setShowModal(true)}
                    /> */}
                    <span className="input-group-append search-btn btn text-primary " onClick={() => setShowModal(true)}>
                        AI Search <i className="feather ml-2 icon-search input-group-text" />
                    </span>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                <Modal.Title className='h5'>
                        Knowledge Base Search 
                        <span className="text-primary" style={{ fontSize: '0.55em', verticalAlign: 'super', marginLeft: '8px' }}>
                            AI Powered
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSearch}>
                        <div className="input-group mb-3">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Search knowledge base..." 
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                autoFocus
                            />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="submit">
                                {isSearching ? <Spinner animation="border" size="sm" />:<i className="feather icon-search" />}
                                </button>
                            </div>
                        </div>
                    </form>

                    {searchResults && (
                        <div className="search-results">
                            {searchResults.ai_answer && (
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h6 className="card-subtitle mb-2 text-muted">AI Answer</h6>
                                        <p className="card-text">{searchResults.ai_answer}</p>
                                    </div>
                                </div>
                            )}
                            {searchResults.articles && searchResults.articles.length > 0 && (
                                
                                        <div className="list-group">
                                            {searchResults.articles.map(article => (
                                                <Link 
                                                    key={article.id}
                                                    to={`/knowledgebase/articles/${article.id}`}
                                                    className="list-group-item list-group-item-action"
                                                    onClick={handleClose}
                                                >
                                                View: <span className='text-primary'>{article.title}</span>
                                                </Link>
                                            ))}
                                        </div>
                        
                            )}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default NavSearch;
