// src/components/ErrorBoundary.js
import React from 'react';
import { postToFrontEndErrors } from '../services/actionsService';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render shows the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error('ErrorBoundary caught an error', error, errorInfo);
        postToFrontEndErrors({
            error_name: error.name,
            message: error.message,
            error: errorInfo
        });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h1>Something went wrong.</h1>
                    <p>Please reload the page or contact support.</p>
                </div>
            );
        }

        return this.props.children; 
    }
}

export default ErrorBoundary;
