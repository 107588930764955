import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './services';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from './contexts/ConfigContext';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpAPI from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { persister, store } from './store';

import ErrorBoundary from './components/ErrorBoundary';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpAPI)
    .init({
        fallbackLng: 'en',
        detection: {
            order: ['localStorage']
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json'
        },
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false
        }
    });

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <PersistGate loading={null} persistor={persister}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </PersistGate>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();

