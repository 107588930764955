import React, { Fragment, Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import AuthGuard from './components/Auth/AuthGuard';
import GuestGuard from './components/Auth/GuestGuard';

import SubscriptionGuard from './components/Auth/SubscriptionGuard';
import { DJANGO } from './config/constant';

export const renderRoutes = (routes = [], django) => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {routes?.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    {
                                        //  route.routes ? renderRoutes(route.routes) : <Component {...props} />
                                        //  :
                                        <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                                    }
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    );
};

export const loginPageRoute = '/auth/signin';
export const billingPageRoute = '/account/billing';
export const manageHolidaysPageRoute = '/company/manage-holidays';
export const settingsPageRoute = '/account/settings';
export const timeOffCalendarRoute = '/inout/time-off-calendar/';
export const taskListRoute = '/tasks/';
export const tasksKanbanRoute = '/request/tasks-kanban';
export const taskCreateRoute = '/tasks/create/';
export const taskDetailsRoute = '/tasks/details/:id';
const publicArticlesRoutePrefix = '/articles/public/';

export const getTaskDetailsPageRoute = (taskId) => {
    return `/tasks/details/${taskId}`;
};

export const getPublicKBArticlesPageRoute = (companySlug) => {
    return `${publicArticlesRoutePrefix}${companySlug}`;
};

export const getPublicArticleDetailPageRoute = (companySlug, id) => {
    return `${publicArticlesRoutePrefix}${companySlug}/details/${id}`;
};

const RedirectToNewBaseURL = () => {
    const location = useLocation();
    const newPath = location.pathname + location.search;
    console.log('Redirecting to new path:', newPath);
    window.location.href = `https://be.humanagement.io${newPath}`;
    return null;
};

const routes = (django) => [
    {
        path: '/:param/api/*',
        exact: true,
        component: RedirectToNewBaseURL
    },
    //these are double linked to handle cases from old django paths
    {
        exact: true,
        guard: GuestGuard,
        path: loginPageRoute,
        component: lazy(() => import('./views/auth/signin/SignIn'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/account/login',
        component: lazy(() => import('./views/auth/signin/SignIn'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signup',
        component: lazy(() => import('./views/auth/signup/SignUp'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/app-sumo-register',
        component: lazy(() => import('./views/auth/signup/AppSumoRegister'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/account/register',
        component: lazy(() => import('./views/auth/signup/SignUp'))
    },

    {
        exact: true,
        path: '/auth/signout',
        component: lazy(() => import('./views/auth/SignOut'))
    },
    {
        exact: true,
        path: '/signout',
        component: lazy(() => import('./views/auth/SignOut'))
    },

    {
        exact: true,
        path: '/404',
        component: lazy(() => import('./views/errors/NotFound404'))
    },
    {
        exact: true,
        path: '/maintenance/coming-soon',
        component: lazy(() => import('./views/maintenance/ComingSoon'))
    },
    {
        exact: true,
        path: '/maintenance/error',
        component: lazy(() => import('./views/maintenance/Error'))
    },
    {
        exact: true,
        path: '/maintenance/offline-ui',
        component: lazy(() => import('./views/maintenance/OfflineUI'))
    },

    {
        exact: true,
        path: '/auth/reset-password',
        component: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
    },
    {
        exact: true,
        path: '/auth/reset-password-confirm/:uuid/:token',
        component: lazy(() => import('./views/auth/reset-password/ResetPasswordConfirm'))
    },

    {
        exact: true,
        path: '/auth/change-password',
        component: lazy(() => import('./views/auth/ChangePassword'))
    },
    {
        exact: true,
        path: '/auth/set-password/:uuid',
        component: lazy(() => import('./views/auth/SetPassword'))
    },
    {
        exact: true,
        path: '/auth/profile-settings',
        component: lazy(() => import('./views/auth/ProfileSettings'))
    },
    {
        exact: true,
        path: '/auth/tabs-auth',
        component: lazy(() => import('./views/auth/TabsAuth'))
    },
    {
        exact: true,
        path: '/auth/subscribe',
        component: lazy(() => import('./views/auth/Subscribe'))
    },
    {
        exact: true,
        path: `${publicArticlesRoutePrefix}:companySlug/`,
        component: lazy(() => import('./views/KnowledgeBase/Public/PublicArticlesList'))
    },
    {
        exact: true,
        path: `${publicArticlesRoutePrefix}:companySlug/:publicKBUrl/`,
        component: lazy(() => import('./views/KnowledgeBase/Public/PublicArticlesList'))
    },
    {
        exact: true,
        path: `${publicArticlesRoutePrefix}:companySlug/details/:id/`,
        component: lazy(() => import('./views/KnowledgeBase/Public/PublicArticleDetail'))
    },

    {
        exact: true,
        guard: SubscriptionGuard,
        path: '/auth/onboarding',
        component: lazy(() => import('./views/auth/onboarding/Onboarding'))
    },
    {
        exact: true,
        path: '/checkout_redirect/:customer_id/:subscription_id/:next_billing_at/',
        guard: AuthGuard,
        component: lazy(() => import('./views/auth/onboarding/CheckoutRedirect'))
        //  todo make this dynamic based on active apps. i.e. in stat mode
        //  I set this to stat it used to be ./views/dashboard/Dashboard
    },
    {
        path: '*',
        layout: DJANGO ? false : AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/',
                guard: SubscriptionGuard,
                component: lazy(() => import('./views/dashboard/Dashboard'))
                //  todo make this dynamic based on active apps. i.e. in stat mode
                //  I set this to stat it used to be ./views/dashboard/Dashboard
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/profile/',
                component: lazy(() => import('./views/users/UserProfile'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/employee/:id',
                component: lazy(() => import('./views/users/UserProfile'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,

                path: '/humesign/',
                component: lazy(() => import('./components/Documents/EmbededSignBuilder'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/employees',
                component: lazy(() => import('./views/users/EmployeeList'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: manageHolidaysPageRoute,
                component: lazy(() => import('./views/company/ManageHolidays'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/create',
                component: lazy(() => import('./views/users/CreateUser'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/responsibilities',
                component: lazy(() => import('./views/users/Responsibilities/Responsibilities'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/positions',
                component: lazy(() => import('./views/users/Positions'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/dashboard/',
                component: lazy(() => import('./views/dashboard/Dashboard'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/org-chart/',
                component: lazy(() => import('./views/OrgChart/OrgChartMaster'))
            },
            {
                exact: true,
                path: '/org-chart/',
                component: lazy(() => import('./views/OrgChart/reactFlow'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/positional-org-chart/',
                component: lazy(() => import('./views/users/EmployeePositionalOrgChart'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/div/',
                component: lazy(() => import('./views/OrgChart/OrgFlow'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/actions/',
                component: lazy(() => import('./views/users/ActionsPage'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/statistics/create',
                component: lazy(() => import('./views/statistics/createStat'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/inout/list/',
                component: lazy(() => import('./views/inout/InoutList'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/inout/working-hours/',
                component: lazy(() => import('./views/inout/WorkingHours'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: timeOffCalendarRoute,
                component: lazy(() => import('./views/inout/TimeOffCalendar'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/statistics/single/',
                component: lazy(() => import('./views/statistics/SingleStat'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/statistics/multi/',
                component: lazy(() => import('./views/statistics/StatGrid'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/statistics/archived/',
                component: lazy(() => import('./views/statistics/Archived'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/statistics/external/',
                component: lazy(() => import('./views/statistics/Zapier'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/statistics/create-stat/',
                component: lazy(() => import('./views/statistics/createStatPage'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/statistics/visualization/',
                component: lazy(() => import('./views/statistics/Visualization'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: billingPageRoute,
                component: lazy(() => import('./views/auth/Billing'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/request',
                component: lazy(() => import('./views/requests/List'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/request/details/:id',
                component: lazy(() => import('./views/requests/RequestDetails'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/request/create',
                component: lazy(() => import('./views/requests/CreateRequest'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: tasksKanbanRoute,
                component: lazy(() => import('./views/requests/Assignments'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: taskListRoute,
                component: lazy(() => import('./views/tasks/List'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: taskDetailsRoute,
                component: lazy(() => import('./views/tasks/TaskDetails'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: taskCreateRoute,
                component: lazy(() => import('./views/tasks/CreateTask'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/purchase-requests',
                component: lazy(() => import('./views/purchaseRequest/List'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/purchase-requests/details/:id',
                component: lazy(() => import('./views/purchaseRequest/PurchaseDetails'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/purchase-requests/create',
                component: lazy(() => import('./views/purchaseRequest/CreatePurchaseRequest'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/articles',
                component: lazy(() => import('./views/KnowledgeBase/KnowledgeBase'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/articles/create',
                component: lazy(() => import('./views/KnowledgeBase/ArticleList/CreateArticle'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/articles/:id',
                component: lazy(() => import('./views/KnowledgeBase/ArticleDetail/ArticleDetailPage'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/articles/:id/edit',
                component: lazy(() => import('./views/KnowledgeBase/ArticleList/EditArticle'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/articles/:id/draft',
                component: lazy(() => import('./views/KnowledgeBase/ArticleList/EditDraftArticle'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/articles/version/:id/',
                component: lazy(() => import('./views/KnowledgeBase/ArticleDetail/ArticleVersionDetailPage'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/acknowledgement-timeline',
                component: lazy(() => import('./views/KnowledgeBase/AcknowledgementTimeline'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/acknowledgement-leaderboard',
                component: lazy(() => import('./views/KnowledgeBase/AcknowledgementLeaderboardPage'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/quiz-acknowledgement-timeline',
                component: lazy(() => import('./views/KnowledgeBase/Quiz/QuizAcknowledgementTimelinePage'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/courses',
                component: lazy(() => import('./views/KnowledgeBase/Courses/CourseList'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/assigned-courses',
                component: lazy(() => import('./views/KnowledgeBase/Courses/AssignedCourses'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/courses/create',
                component: lazy(() => import('./views/KnowledgeBase/Courses/CreateCourse'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/courses/:id',
                component: lazy(() => import('./views/KnowledgeBase/Courses/CourseDetail'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/knowledgebase/courses/:id/edit/',
                component: lazy(() => import('./views/KnowledgeBase/Courses/EditCourse'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/checklist/template/create',
                component: lazy(() => import('./views/checkLists/TemplateViews/createChecklistTemplate'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/checklist/templates',
                component: lazy(() => import('./views/checkLists/TemplateViews/CheckListTemplates'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/checklist/template/edit/:id',
                component: lazy(() => import('./views/checkLists/TemplateViews/EditTemplate'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/checklist/template/:id',
                component: lazy(() => import('./views/checkLists/TemplateViews/TemplateDetails'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/checklist/checklists/',
                component: lazy(() => import('./views/checkLists/AssignedChecklists'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/checklist/edit/:id',
                component: lazy(() => import('./views/checkLists/ChecklistsViews/EditCheckList'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/checklist/create/:id',
                component: lazy(() => import('./views/checkLists/ChecklistsViews/createCheckList'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/checklist/display/:id',
                component: lazy(() => import('./views/checkLists/ChecklistsViews/checklistDetail'))
            },
            {
                exact: true,
                guard: SubscriptionGuard,
                path: settingsPageRoute,
                component: lazy(() => import('./views/users/Settings'))
            },

            {
                exact: true,
                guard: SubscriptionGuard,
                path: '/account/settings/edit-company/',
                component: lazy(() => import('./views/users/EditCompany'))
            },
            {
                path: '*',
                exact: true,
                component: () => <Redirect to={'/account/dashboard/'} />
            }
        ]
    }
];

export default routes;
