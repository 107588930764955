import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/agGrid.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { useDispatch } from 'react-redux';
import { BASENAME, DJANGO, changeApiUrl } from './config/constant';
import routes, { renderRoutes } from './routes';
import userService from './services/userService';
import { ACCOUNT_INITIALIZE } from './store/actions';
import { postToFrontEndErrors } from './services/actionsService';
import debounce from 'lodash.debounce';

// import './App.scss'; // Assuming you have some styles

LicenseManager.setLicenseKey(
    'Using_this_AG_Grid_Enterprise_key_( AG-048786 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Humanagement LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 4 October 2024 )____[v2]_MTcyNzk5NjQwMDAwMA==e206a710510aa81fbe6a72064432cc87'
);



const App = () => {
    const dispatcher = useDispatch();
    const data = document.getElementById('user-data')?.getAttribute('data-props') || '';
    console.log('userdatad', data, data === '{{data}}', data === '{{data}}!');
    // console.log("userdatad2",changeApiUrl,BASENAME)
    const [django, setDjango] = useState(false);

    // Load Chargebee JS
    useEffect(() => {
        const charge = document.createElement('script');
        charge.src = 'https://js.chargebee.com/v2/chargebee.js';
        charge.setAttribute('data-cb-site', process.env.REACT_APP_CHARGEBEEURL || 'humanagement-test');
        document.head.appendChild(charge);

        return () => {
            document.head.removeChild(charge);
        };
    }, []);

    function getCircularReplacer() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return '[Circular]';
                }
                seen.add(value);
            }
            return value;
        };
    }

    // Enhanced Error Handling
    useEffect(() => {
        const isChunkLoadFailed = (error) => {
            if (!error) return false;
            const message = error.message || '';
            return /Loading chunk \d+ failed./i.test(message) || /Failed to fetch dynamically imported module/i.test(message) || /Cannot find module/i.test(message);
        };

        const handleChunkLoadError = debounce((event) => {
            // Get refresh count and timestamp from localStorage
            const errorState = JSON.parse(localStorage.getItem('errorState') || '{"count": 0, "timestamp": 0}');
            const now = Date.now();
            const MAX_REFRESHES = 3;
            const RESET_AFTER = 5 * 60 * 1000; // 5 minutes in milliseconds

            // Reset count if it's been more than 5 minutes since last error
            if (now - errorState.timestamp > RESET_AFTER) {
                errorState.count = 0;
            }

            if (event?.error && isChunkLoadFailed(event.error)) {
                console.error('A chunk failed to load:', event.reason);

                if (errorState.count < MAX_REFRESHES) {
                    // Update error state
                    localStorage.setItem('errorState', JSON.stringify({
                        count: errorState.count + 1,
                        timestamp: now
                    }));
                    window.location.reload();
                } else {
                    // Display error message directly in the DOM
                    document.body.innerHTML = `
                        <div style="text-align: center; padding: 20px;">
                            <h1>There was an error loading the page</h1>
                            <p>Please try again later or contact support if the problem persists.</p>
                        </div>
                    `;
                }
            } else if (event?.error && event.error.name === 'Unhandled Error') {
                console.error('Unhandled Error!!!:', event.error, event);
                // Reload the page for Unhandled Error
                window.location.reload();
            } else {
                console.error('Other Error:', event.error, event);
                const reasonString = JSON.stringify(event.error, getCircularReplacer());

                const errorDetails = {
                    error_name: event.reason?.name,
                    message: event.reason?.message,
                    error: JSON.stringify({
                        defaultPrevented: event.defaultPrevented,
                        message: event.reason?.message,
                        stack: event.reason?.stack,
                        type: event.type,
                        reason: reasonString,
                        fullError: event.error?.toString(),
                        componentStack: event.error?.componentStack || event.reason?.componentStack,
                        currentUrl: window?.location?.href,
                        referrerUrl: document?.referrer,
                        sourceURL: event.error?.sourceURL || event.filename,
                        lineNumber: event.error?.line || event.lineno,
                        columnNumber: event.error?.column || event.colno,
                        userAgent: navigator.userAgent,
                        timestamp: new Date().toISOString(),
                        browserInfo: {
                            viewport: `${window.innerWidth}x${window.innerHeight}`,
                            platform: navigator.platform,
                            language: navigator.language
                        }
                    }, getCircularReplacer())
                };

                postToFrontEndErrors(errorDetails);

                // Check if this is a post-refresh error
                const hasRefreshed = sessionStorage.getItem('errorRefreshed');
                const mainContent = document.body.children.length;
                const existingErrorMessage = document.getElementById('generic-error-message');
                
                if (mainContent <= 1 && !existingErrorMessage) {
                    if (!hasRefreshed) {
                        // First error occurrence - refresh the page
                        sessionStorage.setItem('errorRefreshed', 'true');
                        window.location.reload();
                    } else {
                        // Already refreshed once - show error message
                        document.body.innerHTML = `
                            <div id="generic-error-message" style="text-align: center; padding: 20px;">
                                <h1>There was an error on this page</h1>
                                <p>Please reload the page or contact support.</p>
                            </div>
                        `;
                    }
                }
            }
        }, 500);

        window.addEventListener('unhandledrejection', handleChunkLoadError);
        window.addEventListener('error', handleChunkLoadError);

        return () => {
            window.removeEventListener('unhandledrejection', handleChunkLoadError);
            window.removeEventListener('error', handleChunkLoadError);
        };
    }, []);

//     // Version Check
//     useEffect(() => {
//         const currentVersion = process.env.REACT_APP_VERSION;
// 
//         fetch('/version.json')
//             .then((response) => response.json())
//             .then((data) => {
//                 const latestVersion = data.version;
//                 if (currentVersion !== latestVersion) {
//                     
//                 }
//             })
//             .catch((err) => {
//                 console.error('Failed to fetch version info:', err);
//             });
//     }, []);

    useEffect(() => {
        setDjango(DJANGO);
    }, [DJANGO]);

    useEffect(() => {
        if (data && data !== '{{data}}') {
            (async () => {
                console.log('JSON.parse', data);
                setDjango(true);
                const response = JSON.parse(data);
                changeApiUrl(response.baseUrl);

                try {
                    const userData = await userService.getProfile(response.id);
                    console.log('React Login Success!', userData);
                    dispatcher({
                        type: ACCOUNT_INITIALIZE,
                        payload: { isLoggedIn: true, user: userData.data, token: response.token }
                    });
                } catch (error) {
                    console.error('Failed to fetch user profile:', error);
                    // Optionally handle user fetch errors
                }
            })();
        }
        return () => {
            // dispatcher({ type: LOGOUT });
        };
    }, [data, dispatcher]);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then(registration => {
                    console.log('ServiceWorker registration successful');
                })
                .catch(err => {
                    console.log('ServiceWorker registration failed: ', err);
                });
        }
    }, []);

    return (
        <React.Fragment>
            <Router basename={BASENAME}>{renderRoutes(routes(django), django)}</Router>
            <ToastContainer stacked />
        </React.Fragment>
    );
};

export default App;
