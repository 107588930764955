import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useLocalStorage from '../../../hooks/useLocalStorage';
import userService from '../../../services/userService';
import { useDispatch } from '../../../store';
import { LOGOUT } from '../../../store/actions';
import ActiveFeatures from './ActiveFeatures';
import PersonalInfo from './PersonalInfo';
import SelectSubscriptionPlan from './SelectSubscriptionPlan';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export const ONBOARDING_PERSONAL_INFO = 0;
export const ONBOARDING_ACTIVATE_APPS = 1;
export const ONBOARDING_CHOOSE_PLAN = 2;

export default function Onboarding() {
    const classes = useStyles();
    const [storedValue, setValue] = useLocalStorage('onboardingActiveStep', ONBOARDING_PERSONAL_INFO);
    const [completed, setCompleted] = React.useState({});
    const [isSubmittingPersonalInfo, setisSubmittingPersonalInfo] = useState(false);
    const [steps, setSteps] = useState([
        { id: 1, title: 'Personal Information' },
        { id: 2, title: 'Activate Apps' }
    ]);
    const submitRef = useRef();
    const user = useSelector((state) => state.account.user);
    const history = useHistory();

    useEffect(() => {
        if (user?.has_subscription_plan) {
            return history.push('/account/dashboard/');
        }

        if (!user?.company?.app_sumo_license) {
            // During login from AppSumo, we link the user company with the AppSumo
            // license associated with them and we automatically give them a premium plan.

            // if a user does not have an app sumo license, we make them choose a plan.
            setSteps([...steps, { id: 3, title: 'Choose Plan' }]);
        }
    }, [user]);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return storedValue === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        let newActiveStep;
        if (process.env.REACT_APP_TRAINING === 'True' && storedValue === ONBOARDING_PERSONAL_INFO) {
            // Skip directly to Choose Plan if in training mode
            newActiveStep = ONBOARDING_CHOOSE_PLAN;
        } else {
            newActiveStep = isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : storedValue + 1;
        }
        setValue(newActiveStep);
    };

    const handleBack = () => {
        setValue((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setValue(step);
    };

    const handleReset = () => {
        setValue(ONBOARDING_PERSONAL_INFO);
        setCompleted({});
    };

    const dispatcher = useDispatch();

    const submitPersonalInfo = async () => {
        // The submit button is removed from the PersonalInfo component
        // hence we have to submit after clicking 'Next' button here.
        setisSubmittingPersonalInfo(true);
        submitRef.current.click();
        handleNext();
    };

    function getStepContent(step) {
        if (process.env.REACT_APP_TRAINING === 'True' && step === ONBOARDING_ACTIVATE_APPS) {
            // Skip to next step if trying to show Activate Apps in training mode
            handleNext();
            return null;
        }

        switch (step) {
            case ONBOARDING_PERSONAL_INFO:
                return <PersonalInfo submitBtnRef={submitRef} isSubmitting={isSubmittingPersonalInfo} />;
            case ONBOARDING_ACTIVATE_APPS:
                return <ActiveFeatures callbackFunc={handleNext} />;
            case ONBOARDING_CHOOSE_PLAN:
                return <SelectSubscriptionPlan />;
            default:
                return 'Unknown step';
        }
    }

    const handleLogout = async () => {
        await userService.logoutUser();
        dispatcher({ type: LOGOUT });
        history.push('/auth/signin');
    };

    const handleFinish = () => {
        history.push('/');
    };

    return (
        <>
            <div className="auth-wrapper">
                <div className="">
                    <div className="auth-bg">
                        <span className="r" />
                        <span className="r s" />
                        <span className="r s" />
                        <span className="r" />
                    </div>
                    <Card className="borderless">
                        <Row className="align-items-center">
                            <Card style={{ maxWidth: '900px' }}>
                                <Card.Body>
                                    <div className={classes.root}>
                                        <Link onClick={handleLogout} className="mt-n3 float-right">
                                            Log Out
                                        </Link>
                                        {/*<link>LOG OUT</link>*/}
                                        <Stepper nonLinear activeStep={storedValue}>
                                            {steps.map((step, index) => (
                                                <Step key={step.id}>
                                                    <StepButton onClick={handleStep(index)} completed={completed[index]} disabled={true}>
                                                        {step.title}
                                                    </StepButton>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        <div>
                                            {allStepsCompleted() ? (
                                                <div>
                                                    <Typography className={classes.instructions}>
                                                        All steps completed - you&apos;re finished
                                                    </Typography>
                                                    <Button onClick={handleReset}>Reset</Button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div>{getStepContent(storedValue)}</div>
                                                    <div className="mt-5 d-flex justify-content-between">
                                                        <Button
                                                            disabled={storedValue === ONBOARDING_PERSONAL_INFO}
                                                            onClick={handleBack}
                                                            size="sm"
                                                            variant="outline-secondary"
                                                        >
                                                            Back
                                                        </Button>
                                                        {storedValue !== totalSteps() - 1 && (
                                                            <>
                                                                <Button
                                                                    variant="primary"
                                                                    size="sm"
                                                                    onClick={handleNext}
                                                                    className={classes.button}
                                                                >
                                                                    Skip
                                                                </Button>
                                                                <Button
                                                                    variant="primary"
                                                                    size="sm"
                                                                    onClick={
                                                                        storedValue == ONBOARDING_PERSONAL_INFO
                                                                            ? submitPersonalInfo
                                                                            : handleNext
                                                                    }
                                                                    className={classes.button}
                                                                >
                                                                    Next
                                                                </Button>
                                                            </>
                                                        )}

                                                        {storedValue === totalSteps() - 1 && user?.company?.app_sumo_license && (
                                                            // if the user has an app sumo license, we show the finish button
                                                            // since they do not need to choose a plan
                                                            <Button
                                                                variant="primary"
                                                                size="sm"
                                                                onClick={handleFinish}
                                                                className={classes.button}
                                                            >
                                                                Finish
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Card>
                </div>
            </div>
        </>
    );
}
